<template>
  <div class="zsCheckInContainer">
    <van-nav-bar :title="pageTitle"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content"
         :style="showBtn ? 'padding-bottom:1.06667rem;' : 'padding-bottom:0rem;'">
      <van-form>
        <div class="peField">
          <van-field disabled
                     right-icon="edit"
                     v-model="form.empcode"
                     label="工号"
                     placeholder="请输入工号" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.empname"
                     label="姓名"
                     placeholder="请输入姓名" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.deptname1"
                     label="厂别"
                     placeholder="请输入厂别" />
          <van-field :disabled="formDisable"
                     clickable
                     readonly
                     required
                     right-icon="arrow-down"
                     v-model="form.yymmdd"
                     placeholder="请选择生产日期"
                     label="生产日期"
                     @click="handShowBdPicker" />
          <van-field :disabled="formDisable"
                     clickable
                     readonly
                     required
                     right-icon="arrow-down"
                     v-model="form.orderid"
                     placeholder="请选择工订单号"
                     label="订单号"
                     @click="getWorksWay" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.styleid"
                     label="产品型号"
                     placeholder="请输入产品型号" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.stylename"
                     label="产品名称"
                     placeholder="请输入产品名称" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.fosh"
                     label="粉末色号"
                     placeholder="请输入粉末色号" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.ordernum"
                     label="订单总台数"
                     placeholder="请输入订单总台数" />
          <van-field required
                     :disabled="formDisable"
                     right-icon="edit"
                     v-model="form.scqk"
                     label="生产情况"
                     readonly
                     placeholder="请选择"
                     @click="showScqkPicker = true" />
          <van-popup v-model="showScqkPicker"
                     round
                     position="bottom">
            <van-picker show-toolbar
                        :columns="['完成','未完成']"
                        @cancel="showScqkPicker = false"
                        @confirm="(val) => {form.scqk = val; showScqkPicker = false}" />
          </van-popup>
          <van-field disabled
                     right-icon="edit"
                     v-model="form.userName"
                     label="制表人"
                     placeholder="请输入制表人" />
          <van-field disabled
                     right-icon="edit"
                     v-model="form.userMarkDate"
                     label="制表日期"
                     placeholder="请输入制表日期" />
        </div>
      </van-form>
      <div v-if="dataData.length > 0 && newAutoid != 0"
           class="contentMainTable"
           style="padding-top: 15px; padding-bottom: 15px">
        <div style="width: 100%;font-size: 16px;display: flex;align-items: center;height: 33px;margin-bottom: 5px;">
          <div style="
                        height: 100%;
                        display: flex;
                        align-items: center;
                        width: 100px;
                        text-align: center;
                        background: #efefef;
                        padding-left: 10px;">产量明细</div>
          <div><van-button :disabled="formDisable"
                        type="primary"
                        size="small"
                        @click="getConfigFun">获取配置</van-button></div>
        </div>
        <div style="width: 100%; overflow: auto">
          <table style="border-collapse: collapse;width: 480px;">
            <tr style="background: #e4e9f3; height: 40px;border-bottom: 1px solid #ccc;">
              <th style="border-right: 1px solid #ccc">序号</th>
              <th style="border-right: 1px solid #ccc">工作名称</th>
              <th style="border-right: 1px solid #ccc">每台用量</th>
              <th style="border-right: 1px solid #ccc">本单前期完成数</th>
              <th style="border-right: 1px solid #ccc">实际入仓数（件）</th>
              <!-- <th>操作</th> -->
            </tr>
            <tr v-for="(value, key) in dataData"
                :key="key">
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px;">
                  {{ key + 1 }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.toolsname }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.usenum }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  {{ value.finishnum }}
                </div>
              </td>
              <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  <input v-model="value.innum"
                         style="width: 80px; height: 33px"
                         type="text"
                         class="h5Input"
                         :readonly="formDisable" />
                </div>
              </td>
              <!-- <td style="border-right: 1px solid #ccc">
                <div style="text-align: center; font-size: 14px">
                  <van-button type="primary"
                              size="mini"
                              @click="xiuGaiBtn(value)"
                              :disabled="formDisable">修改</van-button>
                </div>
              </td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="footerBox"
         v-if="showBtn">
      <div class="flexBox"
           v-if="!showCheckBtn">
        <van-button :disabled="canSave"
                    @click="stagingFun"
                    type="primary"
                    style="width: 45%;height: 100%;">暂存</van-button>
        <van-button :disabled="newAutoid == 0"
                    @click="submitFun"
                    type="info"
                    style="width: 45%;height: 100%;">提交</van-button>
      </div>
      <div class="flexBox"
           v-if="showCheckBtn">
        <van-button @click="agreeFun"
                    type="primary"
                    style="width: 45%;height: 100%;">同意</van-button>
        <van-button @click="notAgreeFun"
                    type="info"
                    style="width: 45%;height: 100%;">不同意</van-button>
      </div>
    </div>
    <!-- 选择开日期 -->
    <!-- <van-calendar v-model="showBdPicker"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :default-date="bdDefault"
                  @confirm="onBdConfirm" /> -->
    <!-- 选择日期 -->
    <van-popup v-model="showBdPicker"
               round
               position="bottom">
      <van-datetime-picker v-model="curDeftDate"
                           type="date"
                           :min-date="minDate"
                           :max-date="maxDate"
                           :formatter="formatter"
                           @confirm="onBdConfirm"
                           @cancel="showBdPicker = false" />
    </van-popup>
    <!-- 选择-->
    <van-popup v-model="showWorkTypePicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="worksWayData"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  :default-index="worksWayDefault"
                  @cancel="showWorkTypePicker = false"
                  @confirm="onConfirmWorksWay">
        <template #title>
          <van-search shape="round"
                      v-model="searchWorkType"
                      placeholder="搜索关键词"
                      @input="searchWorksWayData" />
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>
<script>
// getCurUserInfo
import { parseTime, createGguid } from '@/utils'
import {
  getPf2OrderIdList,
  getPf2OrderIdExitValue,
  getCurUserInfo,
  savePf2InputData,
  getPf2InputData,
  savePf2ScmxRecord,
  postPf2InputData,
  getPf2ClmxConfig,
  agreeTmList,
  notAgreeTmlist
} from '@api/wxjj.js'

import { Toast } from 'vant';

export default {
  data () {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      form: {
        empcode: "",
        empname: "",
        empid: "",
        deptname1: "",
        yymmdd: "",
        orderid: "",
        styleid: "",
        stylename: "",
        fosh: "",
        ordernum: "",
        scqk: "",
        userName: "",
        userMarkDate: parseTime(new Date(), '{y}-{m}-{d}'),
      },
      curDeftDate: new Date(),
      showBdPicker: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      showEdPicker: false,
      worksWayData: [],
      jjsclData: [],
      showWorkTypePicker: false,
      showJjsclPicker: false,
      newAutoid: 0,
      dataData: [],
      bdDefault: new Date(),
      edDefault: new Date(),
      worksWayDefault: 0,
      jjsclDefault: 0,
      formDisable: false,
      showCheckBtn: false,
      showBtn: true,
      showScqkPicker: false,
      searchWorkType: ''
    }
  },
  created () {
    this.worksWayData = [];
    getPf2OrderIdList({
      username: this.userInfo.username
    }).then((res) => {
      if (res.data.length > 0)
      {
        for (let i = 0; i < res.data.length; i++)
        {
          this.worksWayData.push(res.data[i].orderid);
        }
      }
    });
    if (this.$route.query.type == 1)
    {
      this.formDisable = true;
      this.showCheckBtn = true;
    }
    if (this.$route.query.type == 3 || this.$route.query.type == 5)
    {
      this.formDisable = true;
      this.showBtn = false;
    }
    setTimeout(() => {
      if (this.$route.query.autoid)
      {
        this.newAutoid = this.$route.query.autoid;
        this.getZsInputData();
      } else
      {
        this.getCurUserInfo();
      }
    }, 300)
  },
  computed: {
    canSave () {
      if (!this.form.yymmdd)
      {
        return true;
      } else
        if (!this.form.orderid)
        {
          return true;
        } else
          if (!this.form.scqk)
          {
            return true;
          } else
          {
            return false;
          }
    },
    pageTitle () {
      let title = '喷粉2登记'
      if (this.$route.query)
      {
        title = this.$route.query.title
      }
      return title
    }
  },
  watch: {
    $route (to, from) {

    }
  },
  methods: {
    // 订单号搜索
    searchWorksWayData () {
      if (this.searchWorkType)
      {
        this.worksWayData = this.worksWayData.filter(it => { return it.indexOf(this.searchWorkType) >= 0 })
      } else
      {
        this.worksWayData = [];
        getPf2OrderIdList({
          username: this.userInfo.username
        }).then((res) => {
          if (res.data.length > 0)
          {
            for (let i = 0; i < res.data.length; i++)
            {
              this.worksWayData.push(res.data[i].orderid);
            }
          }
        });
      }
    },
    formatter (type, val) {
      if (type === "year")
      {
        return val + "年";
      }
      if (type === "month")
      {
        return val + "月";
      }
      if (type === "day")
      {
        return val + "日";
      }
      return val;
    },
    onClickLeft () {
      if (this.$route.query.autoid)
      {
        this.$router.push('/toDoList')
      } else
      {
        this.$router.push('/home')
      }
    },
    getCurUserInfo () {
      getCurUserInfo({
        moduleno: 820,
        username: this.userInfo.username
      }).then(res => {
        const obj = res.data[0];
        this.form.deptname1 = obj.deptname1;
        this.form.empid = obj.empid;
        this.form.empname = obj.empname;
        this.form.empcode = obj.empcode;
        this.form.userName = this.userInfo.username;
        console.log(obj, 'objobj')
      })
    },
    handShowBdPicker () {
      if (!this.formDisable)
      {
        this.showBdPicker = true;
      }
    },
    onBdConfirm (v) {
      this.form.yymmdd = parseTime(v, '{y}-{m}-{d}');
      this.showBdPicker = false;
    },
    handShowEdPicker () {
      if (!this.formDisable)
      {
        this.showEdPicker = true;
      }
    },
    onEdConfirm (v) {
      this.form.ed = parseTime(v, '{y}-{m}-{d}');
      this.showEdPicker = false;
    },
    // 获取工作方式
    getWorksWay () {
      if (!this.formDisable)
      {
        this.showWorkTypePicker = true;
      }
    },
    onConfirmWorksWay (value) {
      this.form.orderid = value;
      getPf2OrderIdExitValue({
        orderid: value,
        username: this.userInfo.username
      }).then(res => {
        const obj = res.data[0];
        this.form.fosh = obj.fosh;
        this.form.ordernum = obj.ordernum;
        this.form.styleid = obj.styleid;
        this.form.stylename = obj.stylename;
        this.showWorkTypePicker = false;
      })

    },
    // 获取胶件生产类
    getJjscl () {
      if (!this.formDisable)
      {
        this.showJjsclPicker = true;
      }
    },
    onConfirmJjscl (value) {
      this.form.jjscl = value;
      this.showJjsclPicker = false;
    },
    // 修改
    xiuGaiBtn (value) {
      savePf2ScmxRecord({
        autoid: value.autoid,
        innum: value.innum,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info);
        } else
        {
          Toast.success('修改成功！');
        }

      })
    },
    // 获取配置
    getConfigFun () {
      getPf2ClmxConfig({
        username: this.userInfo.username,
        autoid: this.newAutoid
      }).then(res => {
        Toast.success('调用成功！');
        this.getZsInputData();
      })
    },
    stagingFun (type) {

      if (!this.form.yymmdd)
      {
        Toast.fail('生产日期不能为空！');
        return false;
      }
      if (!this.form.orderid)
      {
        Toast.fail('订单号不能为空！');
        return false;
      }
      if (!this.form.scqk)
      {
        Toast.fail('生产情况不能为空！');
        return false;
      }
      // createGguid
      savePf2InputData({
        autoid: this.newAutoid,
        listno: createGguid(),
        empid: this.form.empid,
        yymmdd: this.form.yymmdd,
        orderid: this.form.orderid,
        styleid: this.form.styleid,
        stylename: this.form.stylename,
        fosh: this.form.fosh,
        ordernum: this.form.ordernum,
        scqk: this.form.scqk,
        username: this.userInfo.username,
        data: this.dataData
      }).then(res => {
        if (res.data && res.data[0].info == '')
        {
          if (type == 'submit')
          {
            this.saveData();
          } else
          {
            const obj = res.data[0];
            this.newAutoid = obj.autoid;
            this.getZsInputData();
          }
        } else
        {
          Toast.fail(res.data[0].info);
        }
      })
    },
    getZsInputData () {
      getPf2InputData({
        autoid: this.newAutoid,
        username: this.userInfo.username,
      }).then(res => {
        const obj = res.baseinfo[0];
        this.form = {
          empcode: obj.empcode,
          empname: obj.empname,
          empid: obj.empid,
          deptname1: obj.deptname1,
          yymmdd: obj.yymmdd,
          orderid: obj.orderid,
          styleid: obj.styleid,
          stylename: obj.stylename,
          fosh: obj.fosh,
          ordernum: obj.ordernum,
          scqk: obj.scqk,
          userName: obj.crman,
          userMarkDate: obj.crdate,
        }
        this.dataData = res.scmx_data
        this.worksWayDefault = this.worksWayData.indexOf(obj.orderid)
        this.bdDefault = new Date(obj.yymmdd);
        // 暂存成功
      })
    },
    // 提交
    saveData () {
      postPf2InputData({
        autoid: this.newAutoid,
        username: this.userInfo.username
      }).then(res => {
        Toast.success('提交成功！');
        this.onClickLeft();
      })
    },
    submitFun () {
      this.stagingFun('submit')
    },
    agreeFun () {
      agreeTmList({
        moduleno: 820,
        autoid: this.newAutoid,
        username: this.userInfo.username
      }).then(res => {
        Toast.success('审批成功！');
        this.onClickLeft();
      })
    },
    notAgreeFun () {
      notAgreeTmlist({
        moduleno: 820,
        autoid: this.newAutoid,
        username: this.userInfo.username
      }).then(res => {
        Toast.success('审批成功！');
        this.onClickLeft();
      })
    }
  }
}
</script>
<style lang="less" scoped>
.zsCheckInContainer {
  height: 100vh;
  overflow: hidden;

  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }

    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }

  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    padding-bottom: 80px;
  }
}

.footerBox {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;

  .flexBox {
    width: 100vw;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 80%;
    display: flex;
    justify-content: space-around;
  }
}

.contentMainTable {
  width: 100%;
  overflow: hidden;
  padding: 0 0.34667rem;
  background: #fff;

  table {
    th {
      font-size: 26px;
      color: #000000;
    }

    td {
      padding: 15px 20px;

      input {
        font-size: 28px;
      }
    }
  }
}

table tr:nth-child(odd) {
  background-color: #f1f5fc;
}

table tr:nth-child(even) {
  background-color: #ffffff;
}

.h5Input {
  width: 180px;
  border: 1px solid #d2d2d2;
  outline: 0 !important;
  border-radius: 10px;
  padding-left: 8px;
}
</style>